declare const google: any;

import { Injectable, NgZone } from '@angular/core';
import { UtilsService } from '../utils/utils.service';
import { accounts } from 'google-one-tap';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GoogleService {
  private gAccounts: accounts;
  private _gToken$ = new BehaviorSubject<string>('');

  get gToken$(): Observable<string> {
    return this._gToken$.asObservable();
  }

  constructor(
    private ngZone: NgZone,
    private utilsSrv: UtilsService,
  ) {}

  init() {
    this.utilsSrv.loadScript('https://accounts.google.com/gsi/client', this.loadSDK.bind(this));
  }

  renderButton(id: string) {
    if (this.utilsSrv.isPlatformBrowser && this.gAccounts) {
      this.gAccounts.id.renderButton(document.getElementById(id) as HTMLElement, { type: 'icon', width: 40 });
    }
  }

  resetToken() {
    this._gToken$.next('');
  }

  private loadSDK() {
    if (window?.['google']) {
      this.gAccounts = google.accounts;

      google.accounts.id.initialize({
        client_id: environment.googleClientId,
        callback: ({ credential }) => {
          this.ngZone.run(() => {
            this.handleCredential(credential);
          });
        },
      });
    }
  }

  private handleCredential(token: string) {
    this._gToken$.next(token);
  }
}
